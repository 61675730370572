.Box {
  width: 500px;
  height: 500px;
  border-radius: 10px;
  padding: 25px;
}

.Box h3 {
  font-size: 28px;
  font-weight: 700;
  color: var(--background-color);
  background-color: var(--primary-color);
  width: fit-content;
}

.Box ul {
  margin-top: 25px;
  text-decoration: none;
}

.Box ul li {
  font-size: 24px;
  font-weight: 400;
  color: var(--background-color);
  text-decoration: none;
  margin-bottom: 15px;
}

@media (max-width: 1440px) {
  .Box {
    width: 400px;
  }
}

@media (max-width: 1024px) {
  .Box h3 {
    font-size: 24px;
  }

  .Box ul li {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .Box h3 {
    font-size: 20px;
  }

  .Box ul li {
    font-size: 16px;
  }

  .Box {
    width: 100%;
    height: 100%;
  }
}
