.footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    background-color: var(--background-color);
    color: var(--accent-color);
}

.footer-content {
    text-align: left;
    font-size: 16px;
    margin: 0;
}

.footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.footer-links a {
    text-decoration: none;
    color: var(--accent-color);
    transition: all 0.3s ease;
}
.footer-links a:hover {
    color: var(--primary-color);
    transition: all 0.3s ease;
}