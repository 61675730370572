.Skills {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  background-color: var(--background-color);
  margin-top: 100px;
  padding: 25px 0 50px 0;
}

.skills-title {
  color: white;
  margin-top: 0 !important;
}

.Double-box {
  max-width: 1670px;
  display: flex;
  justify-content: center;
  gap: 25px;
  margin: auto;
  padding: 0 25px;
  flex-wrap: wrap;
}

.UI-UX,
.Front-End-Development {
  position: relative;
  background: var(--accent-color);
  overflow: hidden;
}

.UI-UX::before,
.Front-End-Development::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 5px;
  background: #c9da2d;
  transition: width 0.5s ease;
}

.UI-UX:hover::before,
.Front-End-Development:hover::before {
  width: 100%;
}

.UI-UX::after,
.Front-End-Development::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 5px;
  background: #c9da2d;
  transition: width 0.5s ease;
}

.UI-UX:hover::after,
.Front-End-Development:hover::after {
  width: 0%;
}
.UI-UX,
.Front-End-Development {
  flex-grow: 1;
}
