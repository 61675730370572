.thumbNail {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    opacity: 1;
    transition: all 0.5s ease;
    overflow: hidden;
}
.thumbNail img {
    width: 100%;
    height: 100%;
    object-fit:cover;
    cursor: pointer;
}
.thumbNail .thumbNailContent {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s ease;
}
.thumbNail .thumbNailContent h2 {
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    color: var(--accent-color);
}

.thumbNail:hover .thumbNailContent {
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease;
    opacity: 1;
}

@media (max-width:1024px) {
    .thumbNail .thumbNailContent h2{
        font-size: 24px;
    }
}
@media (max-width:767px) {
    .thumbNail .thumbNailContent h2 {
        font-size: 20px;
    }
}
