.Work {
    width: 100vw;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.Work-main {
    width: 100vw;
    height: 75vh;
    position: relative;
}

.portfolio-title {
    display: block;
    margin: auto;
    width: fit-content;
    text-align: center;
    color: var(--background-color);
    z-index: 9999;
    position: relative;
    background-color: white;
}

.Work-container {
    width: 100%;
    height: 100%;
    background-color: var(--accent-color);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s ease all;
    z-index: 100;
    box-shadow: 5px 0 15px rgba(0, 0, 0, 0.5);
}
.Work-container-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    color: var(--background-color);
    padding: 25px;
}
.Work-container-content h2 {
    font-size: 28px;
    font-weight: 700;
    opacity: 0.3;
    transition: all ease 0.3s;
    cursor: pointer;
    margin: 0;
    width: fit-content;
    text-align: center;
}
.Work-container-content h2.active {
    opacity: 1;
    background-color: var(--primary-color);
}
.Work-container-content h2:hover {
    opacity: 1;
    transition: all ease 0.3s;
}
.Work-container-content .seperator {
    font-size: 28px;
    font-weight: 700;
    opacity: 0.3;
}

.InfoData {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    background-color: var(--accent-color);
    overflow: hidden;
}
.InfoDataSlider {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 98;
    background-color: var(--accent-color);
}
.upArrow, .downArrow, .leftArrow, .rightArrow {
    stroke: white;
    stroke-width: 25px;
    user-select: none;
    overflow: visible;
    z-index: 99;
    transition: all 0.5s ease;
    cursor: pointer;
}
.upArrow {
    position: relative;
    display: block;
    margin: auto;
    top: 4%;
}

.downArrow {
    position: relative;
    bottom:-85%;
    display: block;
    margin: auto;
}

.rightArrow {
    position: absolute;
    top: 50%;
    left: 90%;
    transform: translate(-50%, -50%);
    display: none;
}

.leftArrow {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translate(-50%, -50%);
    display: none;
}

.upArrow:hover, .downArrow:hover, .rightArrow:hover, .leftArrow:hover {
    fill: var(--accent-color) !important;
    transition: all 0.5s ease;
    filter: drop-shadow(0 0 5px var(--background-color));
}

@media (max-width:1024px) {
    .Work-container-content h2 , .seperator {
        font-size: 24px;
    }
    .downArrow {
    display: none;
    }
    .upArrow {
    display: none;
    }
    .Work-main {
        height:680px;
    }
    .InfoData {
        width: 100%;
        height: 600px;
        top: 80px;
    }
    .InfoDataSlider {
        flex-direction: row-reverse;
    }
    .Work-container {
        height: 100%;
        width: 100%;
    }
    .rightArrow {
        display: block;
    }
    .leftArrow {
        display: block;
    }
}
@media (max-width:767px) {
    .Work-container-content h2 , .seperator {
        font-size: 20px;
    }
    .rightArrow {
        width: 30px;
    }
    .leftArrow {
        width: 30px;
    }
}