.navbar {
    background-color: rgba(0, 0, 0, 0) !important;
    position: fixed;
    top: 0;
    z-index: 10000;
    width: 100%;
    transition: all 0.3s ease;
}
.scrolled {
    background-color: black !important;
    transition: all 0.3s ease;
    border-bottom: 1px solid white;
}

.container {
    max-width: 1620px !important;
    --bs-gutter-x: 0 !important;
    padding: 0 25px;
}

.navbar-brand {
    color: var(--primary-color) !important;
    font-weight: 700;
    font-size: 36px;
    cursor: pointer;
    position: relative;
}
.navbar-brand::before {
    content: "Egbaria";
    position: absolute;
    background-color: var(--background-color);
    right: -135px;
    opacity: 0;
    transition: all 0.3s ease;
    z-index: 10001;
}
.navbar-brand:hover::before{
    right: 0px;
    opacity: 1;
    transition: all 0.3s ease;
}


.nav-item {
    margin-left: 15px;
    cursor: pointer;
}
.nav-item .nav-link {
    color: var(--accent-color) !important;
    font-size: 32px;
    transition: all 0.3s ease;
    font-weight: 400;
}
.nav-item .nav-link:hover {
    color: var(--primary-color) !important;
    transition: all 0.3s ease;
}
.nav-item .nav-link.active {
    color: var(--primary-color) !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  transition: all 0.3s ease;
}
.navbar-toggler {
  border-color: rgb(255, 255, 255);
  transition: all 0.3s ease;
} 
.navbar-toggler-icon:hover {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(201,218,45, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  transition: all 0.3s ease;
}
.navbar-toggler:hover {
  border-color: var(--primary-color);
  transition: all 0.3s ease;
} 
.navbar-toggler:hover .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(201,218,45, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    transition: all 0.3s ease;
} 
.navbar-toggler:focus {
    box-shadow: 0 0 0 0;
}

@media (max-width: 1440px) {
    .container {
        max-width: 1140px !important;
    }
    .nav-item .nav-link {
        font-size: 32px;
    }
    .navbar-brand {
        color: var(--primary-color) !important;
        font-weight: 700;
        font-size: 36px;
    }
}

@media (max-width: 1024px) {
    .container {
        max-width: 924px !important;
    }
    .nav-item .nav-link {
        font-size: 28px;
    }
    .navbar-brand {
        color: var(--primary-color) !important;
        font-weight: 700;
        font-size: 32px;
    }
}

@media (max-width: 767px) {
    .container {
        width: 100%;
    }
	.nav-item {
        margin-left: 0;
    }

    .navbar-nav {
        margin-top: 15px;
    }
}

