:root {
    --background-color: #000000;
    --primary-color: #C9DA2D;
    --accent-color: #FFFFFF;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@font-face {
    font-family: 'myFont';
    font-weight: 700;
    src: url(assets/fonts/BarlowCondensed-Bold.ttf);
}

@font-face {
    font-family: 'myFont';
    font-weight: 400;
    src: url(assets/fonts/BarlowCondensed-Regular.ttf);
}

*, *::before, *::after {
    box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

html {
    overflow-x: hidden;
}

body {
    width: 100vw !important;
    min-height: 100vh;
    background-color: #ffffff;
    font-family: 'myFont';
    animation: fadeIn 1s ease;
}

img, picture, svg, video {
    display: block;
    max-width: 100%;
}

.hidden {
	opacity: 0;
	transition: 1.5s all ease;
    transform: translateY(25%);
}

.show {
	opacity: 1;
	transition: 1.5s all ease;
    transform: translateY(0%);
}

@media(prefers-reduced-motion) {
	.hidden {
		transition: none;
	}
}