.About {
    width: 100vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
}

.About-paragraph {
    font-size: 32px;
    color: var(--background-color);
    max-width: 850px;
    display: block;
    margin: auto;
}

.About-paragraph .greetings {
    font-weight: 700;
    color: var(--background-color);
    background-color: var(--primary-color);
}

.social-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    text-align: center;
    gap: 25px;
}

.social-links .linkedin,
.social-links .github,
.social-links .behance {
    transition: all 0.3s ease;
}
.social-links .linkedin:hover,
.social-links .github:hover,
.social-links .behance:hover {
    fill: var(--primary-color);
    transition: all 0.3s ease;
}

.CV {
    background-color: var(--background-color);
    color: white;
    font-size: 26px;
    padding: 5px 25px;
    border: none;
    border-radius: 5px;
    display: block;
    width: fit-content;
    margin: auto;
    text-align: center;
    transition: all 0.3s ease;
    height: 50px;
    font-weight: 700;
    text-decoration: none;
}
.CV:hover {
    background-color: var(--primary-color);
    transition: all 0.3s ease;
}

@media (max-width: 1024px) {
    .About-paragraph {
        font-size: 28px;
    }

    .CV {
        font-size: 24px;
    }
}

@media (max-width:890px) {
    .About-paragraph {
        max-width:650px;
    }
}

@media (max-width:767px) {
    .About-paragraph {
        font-size: 24px;
        padding-right: 25px;
        padding-left: 25px;
    }
}