.Sectiontitle {
    margin-top: 100px !important;
    display: block;
    margin: auto;
    width: 100%;
    text-align: center;
    color: var(--background-color);
    font-size: 36px;
    font-weight: 700;
}

@media (max-width: 1024px) {
    .Sectiontitle {
        font-size: 32px;
    }
}