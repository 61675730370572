.Header {
    width: 100vw;
    height: 100vh;
    background-image: url("../../assets/images/background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    color: var(--accent-color);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.Headerparagraph span {
    color: var(--accent-color);
    font-size: 40px;
    font-weight: 400;
}
.Headerparagraph h1 {
    color: var(--accent-color);
    font-size: 64px;
    font-weight: 700;
    margin: 0;
}
.Headerparagraph h1 .span {
    color: var(--primary-color);
    font-size: 64px;
    font-weight: 700;
}
.text-container {
    text-align: center;
}

@media (max-width: 1440px) {
    .Headerparagraph span {
        font-size: 36px;
    }
    .Headerparagraph h1, 
    .Headerparagraph h1 .span {
        font-size: 60px;
    }
}

@media (max-width: 1024px) {
    .Headerparagraph span {
        font-size: 32px;
    }
    .Headerparagraph h1, 
    .Headerparagraph h1 .span {
        font-size: 56px;
    }
}

@media (max-width: 767px) {
    .Headerparagraph span {
        font-size: 28px;
    }
    .Headerparagraph h1, 
    .Headerparagraph h1 .span {
        font-size: 52px ;
    }
}

